import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4fd1c94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "open-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_row = _resolveComponent("van-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_row, { justify: _ctx.justify }, {
      default: _withCtx(() => [
        _createVNode(_component_van_row, { justify: _ctx.justify }, {
          default: _withCtx(() => [
            _createVNode(_component_van_image, {
              class: "gem-image",
              src: `data:image/png;base64,${_ctx.gem.attachment}`,
              radius: "1em"
            }, null, 8, ["src"])
          ]),
          _: 1
        }, 8, ["justify"]),
        _createElementVNode("p", {
          class: "gem-message",
          style: _normalizeStyle(_ctx.gemMessageStyle)
        }, _toDisplayString(_ctx.gem.message), 5)
      ]),
      _: 1
    }, 8, ["justify"]),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.openMessage), 1)
  ], 64))
}