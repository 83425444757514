import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e2b13bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "distance-indicator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleAvatar = _resolveComponent("CircleAvatar")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!

  return (_openBlock(), _createBlock(_component_van_row, {
    class: "info-window",
    type: "flex",
    align: "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_col, { class: "avatar-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_CircleAvatar, {
            avatarUrl: _ctx.gemCreator.avatar,
            showLoading: false,
            showError: false,
            radius: 1.5
          }, null, 8, ["avatarUrl", "radius"])
        ]),
        _: 1
      }),
      _createVNode(_component_van_col, { class: "marker-text-info" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.displayDistance), 1),
          _createElementVNode("p", null, "From: " + _toDisplayString(_ctx.gemCreator.displayName), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.displayDropDateTime), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}