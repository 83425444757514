import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FriendCell = _resolveComponent("FriendCell")!

  return (_openBlock(), _createBlock(_component_FriendCell, {
    friend: _ctx.friend,
    isClickable: true,
    labelOverride: _ctx.previewMessage,
    onClick: _ctx.goToLogsPreview
  }, null, 8, ["friend", "labelOverride", "onClick"]))
}