
import { defineComponent, PropType } from "vue";
import { Gem } from "@/interfaces";
import BottomSheet from "@/components/BottomSheet.vue";

export default defineComponent({
  props: {
    gem: {
      type: Object as PropType<Gem>,
      required: true,
    },
  },
  components: {
    BottomSheet,
  },
  data() {
    return {
      show: true,
      addBackground: {
        backgroundImage: `url(data:image/png;base64,${this.gem.attachment}`,
      },
    };
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },
});
