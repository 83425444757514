import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BottomSheet = _resolveComponent("BottomSheet")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bg-container",
    style: _normalizeStyle(_ctx.addBackground)
  }, [
    _createVNode(_component_BottomSheet, { gem: _ctx.gem }, null, 8, ["gem"])
  ], 4))
}