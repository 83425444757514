
import { Gem, User } from "@/interfaces";
import { defineComponent, PropType, ref, computed } from "vue";
import { Row, Button } from "vant";
import { formatDateTime } from "@/utils/date";
import { getLocationNameFromLatLng } from "@/utils/geolocation";
import { HOME_ROUTE } from "@/constants";
import CalendarIcon from "@/assets/icons/calendar.svg";
import MapIcon from "@/assets/icons/map.svg";
import FriendCell from "@/components/FriendCell.vue";

export default defineComponent({
  setup() {
    const collapsed = ref(true);
    const toggleBottomSheet = () => (collapsed.value = !collapsed.value);
    // how to set this dynamically...
    const BOTTOM_SHEET_HEIGHT = 160;
    const BOTTOM_SHEET_HEIGHT_COLLAPSED = 50;
    const bottomSheetHeight = computed(
      () =>
        `${
          collapsed.value ? BOTTOM_SHEET_HEIGHT_COLLAPSED : BOTTOM_SHEET_HEIGHT
        }px`
    );

    return {
      collapsed,
      toggleBottomSheet,
      bottomSheetHeight,
      CalendarIcon,
      MapIcon,
    };
  },
  mounted() {
    getLocationNameFromLatLng(this.gem.position).then(
      (locationName: string) => {
        this.gemAddress = locationName;
      }
    );
  },
  components: {
    "van-button": Button,
    "van-row": Row,
    FriendCell,
  },
  props: {
    gem: {
      type: Object as PropType<Gem>,
      required: true,
    },
  },
  data() {
    return {
      friend: this.gem.createdBy as User,
      gemAddress: "" as string,
    };
  },
  computed: {
    displayDropDateTime() {
      return `${formatDateTime(this.gem.createdAt)}`;
    },
  },
  methods: {
    backToHome() {
      this.$router.replace(HOME_ROUTE);
    },
    swipeBottomSheetDown() {
      if (this.collapsed == false) {
        this.collapsed = !this.collapsed;
      }
    },
    swipeBottomSheetUp() {
      if (this.collapsed == true) {
        this.collapsed = !this.collapsed;
      }
    },
  },
});
