
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { CellGroup, Cell } from "vant";
import { EDIT_PROFILE_ROUTE, FRIEND_LIST_ROUTE } from "@/constants";
import { User } from "@/interfaces";
import ProfileAvatar from "@/components/ProfileAvatar.vue";
import Header from "@/components/Header.vue";
import SemiCircleBg from "@/components/SemiCircleBg.vue";

export default defineComponent({
  data() {
    return {
      color: "#ffffff",
      store: useStore(),
    };
  },
  computed: {
    self(): User {
      return this.store.state.user.self;
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToEditProfile() {
      this.$router.push(EDIT_PROFILE_ROUTE);
    },
    goToFriendList() {
      this.$router.push(FRIEND_LIST_ROUTE);
    },
  },
  components: {
    ProfileAvatar,
    CellGroup,
    Cell,
    Header,
    SemiCircleBg,
  },
});
