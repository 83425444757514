
import { defineComponent, PropType } from "vue";
import { Button } from "vant";
import { Gem, GemColor } from "@/interfaces";
import { getEnumKeyByEnumValue } from "@/utils/enum";

export default defineComponent({
  props: {
    gem: {
      type: Object as PropType<Gem>,
      required: true,
    },
  },
  components: {
    "van-button": Button,
  },
  computed: {
    getPoster() {
      const gemColorName = getEnumKeyByEnumValue(GemColor, this.gem.color);
      return require(`@/assets/images/gem-found-${gemColorName.toLowerCase()}.png`);
    },
    getVideo() {
      const gemColorName = getEnumKeyByEnumValue(GemColor, this.gem.color);
      return require(`@/assets/videos/gem-found-${gemColorName.toLowerCase()}.mp4`);
    },
  },
});
