
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Field } from "vant";
import GemHeader from "@/components/GemHeader.vue";
import { GemColor } from "@/interfaces";
import { mapState } from "vuex";

export default defineComponent({
  components: {
    Field,
    GemHeader,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  computed: {
    ...mapState({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: (state: any) => state.gems.dropGemFormState.color as GemColor,
    }),
    message(): string {
      return this.store.state.gems.dropGemFormState.message;
    },
  },
});
