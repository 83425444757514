import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createBlock(_component_van_image, {
    src: _ctx.getGemImageUrl,
    width: "32vw",
    height: "32vw"
  }, null, 8, ["src"]))
}