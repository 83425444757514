
import {
  PROFILE_ROUTE,
  ADD_FRIENDS_ROUTE,
  FRIEND_REQUESTS_ROUTE,
  GEM_LOGS_ROUTE,
} from "@/constants";
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import CircleAvatar from "@/components/CircleAvatar.vue";
import HamburgerMenuIcon from "@/assets/icons/menu-hamburger.svg";
import Notification from "@/components/Notification.vue";
import { Toast, Row } from "vant";

export default defineComponent({
  setup() {
    const collapsed = ref(true);
    const toggleMenu = () => (collapsed.value = !collapsed.value);
    const MENU_WIDTH_EXPANDED = 14;
    const MENU_WIDTH_COLLAPSED = 0;
    const menuWidth = computed(
      () => `${collapsed.value ? MENU_WIDTH_COLLAPSED : MENU_WIDTH_EXPANDED}em`
    );

    return {
      collapsed,
      toggleMenu,
      menuWidth,
      HamburgerMenuIcon,
    };
  },
  components: {
    "van-row": Row,
    CircleAvatar,
    Notification,
  },
  props: {
    requestCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { store: useStore() };
  },
  computed: {
    self() {
      return this.store.state.user.self;
    },
  },
  methods: {
    goToProfile() {
      this.$router.push(PROFILE_ROUTE);
    },
    goToLogs() {
      this.$router.push(GEM_LOGS_ROUTE);
    },
    goToAddFriends() {
      this.$router.push(ADD_FRIENDS_ROUTE);
    },
    goToFriendRequests() {
      this.$router.push(FRIEND_REQUESTS_ROUTE);
    },
    clickAway() {
      if (!this.collapsed) {
        this.toggleMenu();
      }
    },
    logOut() {
      Toast.loading("Logging out...");
      setTimeout(() => {
        this.store.dispatch("logout");
      }, 2000);
    },
  },
});
