import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GemMessageOpen = _resolveComponent("GemMessageOpen")!
  const _component_GemMessageClosed = _resolveComponent("GemMessageClosed")!
  const _component_van_row = _resolveComponent("van-row")!

  return (_openBlock(), _createBlock(_component_van_row, { class: "base" }, {
    default: _withCtx(() => [
      (_ctx.hasPickedUpGem)
        ? (_openBlock(), _createBlock(_component_GemMessageOpen, {
            key: 0,
            gem: _ctx.gem,
            gemMessageStyle: _ctx.gemMessageStyle,
            justify: _ctx.justify,
            isSentBySelf: _ctx.isSentBySelf
          }, null, 8, ["gem", "gemMessageStyle", "justify", "isSentBySelf"]))
        : (_openBlock(), _createBlock(_component_GemMessageClosed, {
            key: 1,
            gemMessageStyle: _ctx.gemMessageStyle,
            gem: _ctx.gem,
            justify: _ctx.justify,
            isSentBySelf: _ctx.isSentBySelf
          }, null, 8, ["gemMessageStyle", "gem", "justify", "isSentBySelf"]))
    ]),
    _: 1
  }))
}