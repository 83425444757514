export const HOME_ROUTE = "/";
export const DROP_GEM_ROUTE = "/drop";
export const PICKUP_GEM_ROUTE = "/pickup";
export const PROFILE_ROUTE = "/profile";
export const EDIT_PROFILE_ROUTE = "/profile/edit";
export const ADD_FRIENDS_ROUTE = "/add-friends";
export const FRIEND_REQUESTS_ROUTE = "/friend-requests";
export const FRIEND_LIST_ROUTE = "/friends-list";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";
export const GEM_LOGS_ROUTE = "/logs";
export const GEM_LOGS_DETAILS_ROUTE = "/logs/:id";
