
import { defineComponent } from "vue";
import { formatDistance } from "@/utils/geolocation";

export default defineComponent({
  props: {
    nearestGemDistance: {
      type: Number,
    },
    numberGemsPendingCollection: {
      type: Number,
      required: true,
    },
    shouldShowTutorial: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    displayNearestGemDistance() {
      if (!this.nearestGemDistance) {
        return null;
      }
      return formatDistance(this.nearestGemDistance);
    },
  },
});
