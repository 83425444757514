
import { createApp, ref, defineComponent, PropType, watchEffect } from "vue";
import { useGeolocation } from "@/useGeolocation";
import { GoogleMap, Marker, CustomControl } from "vue3-google-map";
import {
  DEFAULT_MAP_CONFIG,
  DROP_GEM_ROUTE,
  GEM_PICKUP_RADIUS_THRESHOLD,
  PICKUP_GEM_ROUTE,
} from "@/constants";
import { Gem, GemColor, LatLng } from "@/interfaces";
import { getDistanceFromLatLonInKm } from "@/utils/geolocation";
import GemMarkerInfoWindow from "./GemMarkerInfoWindow.vue";
import MapUserMarker from "./MapUserMarker.vue";
import GemMapPopup from "./GemMapPopup.vue";
import { mapState, useStore } from "vuex";
import { getEnumKeyByEnumValue } from "@/utils/enum";
import { Toast } from "vant";

const GOOGLE_API_KEY = window.env.VUE_APP_GOOGLE_API_KEY;

type GemMarkerOptions = Gem &
  Pick<google.maps.MarkerOptions, "map" | "position" | "icon">;

export default defineComponent({
  props: {
    gems: {
      type: Array as PropType<Gem[]>,
      default: () => [],
    },
  },
  components: {
    GoogleMap,
    Marker,
    CustomControl,
    MapUserMarker,
    GemMapPopup,
  },
  async setup() {
    const mapRef = ref<InstanceType<typeof GoogleMap> | null>(null);
    const gemMarkerInfoWindowRef = ref<google.maps.InfoWindow | null>(null);
    const openedInfoWindowGem = ref<Gem | null>(null);
    const store = useStore();
    const isGemMapFirstMount = store.state.gems.isGemMapFirstMount;

    const shouldShowPopup = ref<boolean>(isGemMapFirstMount);

    const { getLocation } = useGeolocation();

    watchEffect(
      () => {
        const popupCloseEvents = ["mousedown", "dragstart"];
        popupCloseEvents.forEach((event) => {
          mapRef?.value?.map?.addListener(event, () => {
            shouldShowPopup.value = false;
          });
        });
      },
      {
        flush: "post",
      }
    );

    await getLocation();

    store.commit("setIsGemMapFirstMount", false);
    return {
      mapRef,
      gemMarkerInfoWindowRef,
      userCircleRadius: GEM_PICKUP_RADIUS_THRESHOLD,
      currGemIdx: null as null | number,
      apiKey: GOOGLE_API_KEY,
      shouldShowPopup,
      openedInfoWindowGem,
      store,
    };
  },

  beforeUpdate() {
    this.gemMarkerRefs = new Set();
  },
  data() {
    return {
      gemMarkerRefs: new Set() as Set<InstanceType<typeof Marker>>,
    };
  },

  computed: {
    ...mapState({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      currPosition: (state: any) => state.user.currPosition as LatLng,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      shouldShowTutorial: (state: any) =>
        state.user.shouldShowTutorial as boolean,
    }),
    mapConfig() {
      return {
        ...DEFAULT_MAP_CONFIG,
        currPosition: this.currPosition,
      };
    },
    nearestGemDistance() {
      if (this.sortedGems.length === 0) {
        return undefined;
      }
      const nearest = this.sortedGems[0];
      return getDistanceFromLatLonInKm(nearest.position, this.currPosition);
    },
    sortedGems(): Gem[] {
      return [...this.gems].sort((gem1, gem2) => {
        return (
          getDistanceFromLatLonInKm(gem1.position, this.currPosition) -
          getDistanceFromLatLonInKm(gem2.position, this.currPosition)
        );
      });
    },
    gemMarkerOptions(): GemMarkerOptions[] {
      return this.gems.map((gem) => {
        const gemImageUrl = this.getGemImageUrl(gem);
        return {
          ...gem,
          icon: gemImageUrl,
        };
      }) as GemMarkerOptions[];
    },
    shouldShowPickupGemButton(): boolean {
      if (this.openedInfoWindowGem === null) {
        return false;
      }
      const distToSelf = getDistanceFromLatLonInKm(
        this.openedInfoWindowGem.position,
        this.currPosition
      );

      return distToSelf <= GEM_PICKUP_RADIUS_THRESHOLD;
    },
  },

  methods: {
    setGemMarkerRef(el: InstanceType<typeof Marker>) {
      if (el) {
        this.gemMarkerRefs.add(el);
      }
    },
    centerMapOnCurrentLocation() {
      this.mapRef?.map?.panTo(this.currPosition);
      Toast.loading({
        duration: 1000,
        forbidClick: true,
        loadingType: "spinner",
        message: "Centering map to your location",
      });
    },

    getGemImageUrl(gem: Gem) {
      const gemColorName = getEnumKeyByEnumValue(GemColor, gem.color);
      return require(`@/assets/images/${gemColorName.toLowerCase()}_64.png`);
    },

    nextGem() {
      console.assert(this.sortedGems.length > 0);

      if (this.currGemIdx === null) {
        this.currGemIdx = 0;
      } else {
        this.currGemIdx = (this.currGemIdx + 1) % this.sortedGems.length;
      }
      const currGem = this.sortedGems[this.currGemIdx];

      this.mapRef?.map?.panTo(currGem.position);
      this.showGemMarkerInfoWindow(currGem);
    },

    prevGem() {
      console.assert(this.sortedGems.length > 0);

      if (this.currGemIdx === null || this.currGemIdx === 0) {
        this.currGemIdx = this.sortedGems.length - 1;
      } else {
        this.currGemIdx -= 1;
      }
      const currGem = this.sortedGems[this.currGemIdx];

      this.mapRef?.map?.panTo(currGem.position);
      this.showGemMarkerInfoWindow(currGem);
    },

    goToDropGem() {
      this.$router.push(DROP_GEM_ROUTE);
    },

    onGemMarkerClick(markerOptions: GemMarkerOptions) {
      this.showGemMarkerInfoWindow(markerOptions);
    },
    showGemMarkerInfoWindow(gem: Gem) {
      const marker = Array.from(this.gemMarkerRefs).find(
        (marker) => marker.$props.options.position == gem.position
      );

      const distFromSelf = getDistanceFromLatLonInKm(
        gem.position,
        this.currPosition
      );

      const infoWindow = createApp(GemMarkerInfoWindow, {
        distance: distFromSelf,
        gemCreator: gem.createdBy,
        dropTime: gem.createdAt,
      });
      const el = document.createElement("div");
      const mountedApp = infoWindow.mount(el);
      const contentString = mountedApp.$el.outerHTML;

      if (!this.gemMarkerInfoWindowRef) {
        this.gemMarkerInfoWindowRef = new google.maps.InfoWindow();
      }
      // Close the existing window (if any)
      this.gemMarkerInfoWindowRef.close();
      this.gemMarkerInfoWindowRef.setContent(contentString);
      this.gemMarkerInfoWindowRef.open({
        anchor: marker?.marker.component.value,
        map: this.mapRef?.map,
        shouldFocus: false,
      });

      const gemInfoWindowCloseEvents = ["click", "mousedown"];
      gemInfoWindowCloseEvents.forEach((event) => {
        this.mapRef?.map?.addListener(event, () => {
          this.gemMarkerInfoWindowRef?.close();
          this.openedInfoWindowGem = null;
        });
      });

      this.openedInfoWindowGem = gem;
    },

    togglePopup() {
      this.shouldShowPopup = !this.shouldShowPopup;
    },
    hidePopup() {
      this.shouldShowPopup = false;
      this.store.commit("setShouldShowTutorial", false);
    },

    pickUpGem() {
      if (!this.openedInfoWindowGem) {
        console.error("No gem selected for picking up");
        return;
      }
      this.store
        .dispatch("pickUpGem", { gem: this.openedInfoWindowGem })
        .then(() => this.$router.push(PICKUP_GEM_ROUTE))
        .catch((err) => Toast.fail(`Failed to pick up gem: ${err}`));
    },
  },
});
