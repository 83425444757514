/**
 * @fileoverview gRPC-Web generated client stub for usersapi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.usersapi = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usersapi.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usersapi.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetOwnProfileResponse>}
 */
const methodDescriptor_UserService_GetOwnProfile = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/GetOwnProfile',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.usersapi.GetOwnProfileResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetOwnProfileResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetOwnProfileResponse>}
 */
const methodInfo_UserService_GetOwnProfile = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.GetOwnProfileResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetOwnProfileResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.GetOwnProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.GetOwnProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.getOwnProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/GetOwnProfile',
      request,
      metadata || {},
      methodDescriptor_UserService_GetOwnProfile,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.GetOwnProfileResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.getOwnProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/GetOwnProfile',
      request,
      metadata || {},
      methodDescriptor_UserService_GetOwnProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.SearchByUsernameRequest,
 *   !proto.usersapi.SearchByUsernameResponse>}
 */
const methodDescriptor_UserService_SearchByUsername = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/SearchByUsername',
  grpc.web.MethodType.UNARY,
  proto.usersapi.SearchByUsernameRequest,
  proto.usersapi.SearchByUsernameResponse,
  /**
   * @param {!proto.usersapi.SearchByUsernameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.SearchByUsernameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.SearchByUsernameRequest,
 *   !proto.usersapi.SearchByUsernameResponse>}
 */
const methodInfo_UserService_SearchByUsername = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.SearchByUsernameResponse,
  /**
   * @param {!proto.usersapi.SearchByUsernameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.SearchByUsernameResponse.deserializeBinary
);


/**
 * @param {!proto.usersapi.SearchByUsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.SearchByUsernameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.SearchByUsernameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.searchByUsername =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/SearchByUsername',
      request,
      metadata || {},
      methodDescriptor_UserService_SearchByUsername,
      callback);
};


/**
 * @param {!proto.usersapi.SearchByUsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.SearchByUsernameResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.searchByUsername =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/SearchByUsername',
      request,
      metadata || {},
      methodDescriptor_UserService_SearchByUsername);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetFriendsResponse>}
 */
const methodDescriptor_UserService_GetFriends = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/GetFriends',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.usersapi.GetFriendsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetFriendsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetFriendsResponse>}
 */
const methodInfo_UserService_GetFriends = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.GetFriendsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetFriendsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.GetFriendsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.GetFriendsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.getFriends =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/GetFriends',
      request,
      metadata || {},
      methodDescriptor_UserService_GetFriends,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.GetFriendsResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.getFriends =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/GetFriends',
      request,
      metadata || {},
      methodDescriptor_UserService_GetFriends);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetPendingFriendsResponse>}
 */
const methodDescriptor_UserService_GetPendingFriends = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/GetPendingFriends',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.usersapi.GetPendingFriendsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetPendingFriendsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetPendingFriendsResponse>}
 */
const methodInfo_UserService_GetPendingFriends = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.GetPendingFriendsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetPendingFriendsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.GetPendingFriendsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.GetPendingFriendsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.getPendingFriends =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/GetPendingFriends',
      request,
      metadata || {},
      methodDescriptor_UserService_GetPendingFriends,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.GetPendingFriendsResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.getPendingFriends =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/GetPendingFriends',
      request,
      metadata || {},
      methodDescriptor_UserService_GetPendingFriends);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetFriendRequestsResponse>}
 */
const methodDescriptor_UserService_GetFriendRequests = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/GetFriendRequests',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.usersapi.GetFriendRequestsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetFriendRequestsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.usersapi.GetFriendRequestsResponse>}
 */
const methodInfo_UserService_GetFriendRequests = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.GetFriendRequestsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.GetFriendRequestsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.GetFriendRequestsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.GetFriendRequestsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.getFriendRequests =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/GetFriendRequests',
      request,
      metadata || {},
      methodDescriptor_UserService_GetFriendRequests,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.GetFriendRequestsResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.getFriendRequests =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/GetFriendRequests',
      request,
      metadata || {},
      methodDescriptor_UserService_GetFriendRequests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.FriendRequest,
 *   !proto.usersapi.AddFriendRequestResponse>}
 */
const methodDescriptor_UserService_AddFriendRequest = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/AddFriendRequest',
  grpc.web.MethodType.UNARY,
  proto.usersapi.FriendRequest,
  proto.usersapi.AddFriendRequestResponse,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.AddFriendRequestResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.FriendRequest,
 *   !proto.usersapi.AddFriendRequestResponse>}
 */
const methodInfo_UserService_AddFriendRequest = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usersapi.AddFriendRequestResponse,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usersapi.AddFriendRequestResponse.deserializeBinary
);


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usersapi.AddFriendRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usersapi.AddFriendRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.addFriendRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/AddFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_AddFriendRequest,
      callback);
};


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usersapi.AddFriendRequestResponse>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.addFriendRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/AddFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_AddFriendRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_AcceptFriendRequest = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/AcceptFriendRequest',
  grpc.web.MethodType.UNARY,
  proto.usersapi.FriendRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_AcceptFriendRequest = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.acceptFriendRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/AcceptFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_AcceptFriendRequest,
      callback);
};


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.acceptFriendRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/AcceptFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_AcceptFriendRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_DeclineFriendRequest = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/DeclineFriendRequest',
  grpc.web.MethodType.UNARY,
  proto.usersapi.FriendRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_DeclineFriendRequest = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.declineFriendRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/DeclineFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_DeclineFriendRequest,
      callback);
};


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.declineFriendRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/DeclineFriendRequest',
      request,
      metadata || {},
      methodDescriptor_UserService_DeclineFriendRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_Block = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/Block',
  grpc.web.MethodType.UNARY,
  proto.usersapi.FriendRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.FriendRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_Block = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.FriendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.block =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/Block',
      request,
      metadata || {},
      methodDescriptor_UserService_Block,
      callback);
};


/**
 * @param {!proto.usersapi.FriendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.block =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/Block',
      request,
      metadata || {},
      methodDescriptor_UserService_Block);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usersapi.EditProfileRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_EditOwnProfile = new grpc.web.MethodDescriptor(
  '/usersapi.UserService/EditOwnProfile',
  grpc.web.MethodType.UNARY,
  proto.usersapi.EditProfileRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.EditProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usersapi.EditProfileRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_EditOwnProfile = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usersapi.EditProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usersapi.EditProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usersapi.UserServiceClient.prototype.editOwnProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usersapi.UserService/EditOwnProfile',
      request,
      metadata || {},
      methodDescriptor_UserService_EditOwnProfile,
      callback);
};


/**
 * @param {!proto.usersapi.EditProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usersapi.UserServicePromiseClient.prototype.editOwnProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usersapi.UserService/EditOwnProfile',
      request,
      metadata || {},
      methodDescriptor_UserService_EditOwnProfile);
};


module.exports = proto.usersapi;

