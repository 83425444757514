import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleAvatar = _resolveComponent("CircleAvatar")!

  return (_openBlock(), _createBlock(_component_CircleAvatar, {
    avatarUrl: _ctx.avatarUrl,
    radius: 5,
    class: "avatar-border"
  }, null, 8, ["avatarUrl"]))
}