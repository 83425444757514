import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, {
    name: _ctx.currentStageName,
    gem: _ctx.gem,
    onNextStage: _ctx.nextStage
  }, null, 8, ["name", "gem", "onNextStage"]))
}