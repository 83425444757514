/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.exportSymbol('proto.usersapi.AddFriendRequestResponse', null, global);
goog.exportSymbol('proto.usersapi.AddFriendRequestStatus', null, global);
goog.exportSymbol('proto.usersapi.EditProfileRequest', null, global);
goog.exportSymbol('proto.usersapi.FriendRequest', null, global);
goog.exportSymbol('proto.usersapi.GetFriendRequestsResponse', null, global);
goog.exportSymbol('proto.usersapi.GetFriendsResponse', null, global);
goog.exportSymbol('proto.usersapi.GetOwnProfileResponse', null, global);
goog.exportSymbol('proto.usersapi.GetPendingFriendsResponse', null, global);
goog.exportSymbol('proto.usersapi.PendingFriendRequest', null, global);
goog.exportSymbol('proto.usersapi.SearchByUsernameRequest', null, global);
goog.exportSymbol('proto.usersapi.SearchByUsernameResponse', null, global);
goog.exportSymbol('proto.usersapi.User', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.User.displayName = 'proto.usersapi.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.User.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatar: msg.getAvatar_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.User}
 */
proto.usersapi.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.User;
  return proto.usersapi.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.User}
 */
proto.usersapi.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAvatar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatar_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.usersapi.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.usersapi.User.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.usersapi.User.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.usersapi.User.prototype.setDisplayName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.usersapi.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.usersapi.User.prototype.setUsername = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes avatar = 4;
 * @return {!(string|Uint8Array)}
 */
proto.usersapi.User.prototype.getAvatar = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes avatar = 4;
 * This is a type-conversion wrapper around `getAvatar()`
 * @return {string}
 */
proto.usersapi.User.prototype.getAvatar_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAvatar()));
};


/**
 * optional bytes avatar = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAvatar()`
 * @return {!Uint8Array}
 */
proto.usersapi.User.prototype.getAvatar_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAvatar()));
};


/** @param {!(string|Uint8Array)} value */
proto.usersapi.User.prototype.setAvatar = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.GetOwnProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usersapi.GetOwnProfileResponse.repeatedFields_, null);
};
goog.inherits(proto.usersapi.GetOwnProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.GetOwnProfileResponse.displayName = 'proto.usersapi.GetOwnProfileResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usersapi.GetOwnProfileResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.GetOwnProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.GetOwnProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.GetOwnProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetOwnProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.usersapi.User.toObject(includeInstance, f),
    friendsList: jspb.Message.toObjectList(msg.getFriendsList(),
    proto.usersapi.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.GetOwnProfileResponse}
 */
proto.usersapi.GetOwnProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.GetOwnProfileResponse;
  return proto.usersapi.GetOwnProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.GetOwnProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.GetOwnProfileResponse}
 */
proto.usersapi.GetOwnProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.addFriends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.GetOwnProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.GetOwnProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.GetOwnProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetOwnProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
  f = message.getFriendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User info = 1;
 * @return {?proto.usersapi.User}
 */
proto.usersapi.GetOwnProfileResponse.prototype.getInfo = function() {
  return /** @type{?proto.usersapi.User} */ (
    jspb.Message.getWrapperField(this, proto.usersapi.User, 1));
};


/** @param {?proto.usersapi.User|undefined} value */
proto.usersapi.GetOwnProfileResponse.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.usersapi.GetOwnProfileResponse.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.usersapi.GetOwnProfileResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User friends = 2;
 * @return {!Array<!proto.usersapi.User>}
 */
proto.usersapi.GetOwnProfileResponse.prototype.getFriendsList = function() {
  return /** @type{!Array<!proto.usersapi.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usersapi.User, 2));
};


/** @param {!Array<!proto.usersapi.User>} value */
proto.usersapi.GetOwnProfileResponse.prototype.setFriendsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.usersapi.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usersapi.User}
 */
proto.usersapi.GetOwnProfileResponse.prototype.addFriends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.usersapi.User, opt_index);
};


proto.usersapi.GetOwnProfileResponse.prototype.clearFriendsList = function() {
  this.setFriendsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.SearchByUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.SearchByUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.SearchByUsernameRequest.displayName = 'proto.usersapi.SearchByUsernameRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.SearchByUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.SearchByUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.SearchByUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.SearchByUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchQuery: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.SearchByUsernameRequest}
 */
proto.usersapi.SearchByUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.SearchByUsernameRequest;
  return proto.usersapi.SearchByUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.SearchByUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.SearchByUsernameRequest}
 */
proto.usersapi.SearchByUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.SearchByUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.SearchByUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.SearchByUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.SearchByUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_query = 1;
 * @return {string}
 */
proto.usersapi.SearchByUsernameRequest.prototype.getSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.usersapi.SearchByUsernameRequest.prototype.setSearchQuery = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.SearchByUsernameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usersapi.SearchByUsernameResponse.repeatedFields_, null);
};
goog.inherits(proto.usersapi.SearchByUsernameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.SearchByUsernameResponse.displayName = 'proto.usersapi.SearchByUsernameResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usersapi.SearchByUsernameResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.SearchByUsernameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.SearchByUsernameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.SearchByUsernameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.SearchByUsernameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.usersapi.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.SearchByUsernameResponse}
 */
proto.usersapi.SearchByUsernameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.SearchByUsernameResponse;
  return proto.usersapi.SearchByUsernameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.SearchByUsernameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.SearchByUsernameResponse}
 */
proto.usersapi.SearchByUsernameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.SearchByUsernameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.SearchByUsernameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.SearchByUsernameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.SearchByUsernameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.usersapi.User>}
 */
proto.usersapi.SearchByUsernameResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.usersapi.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usersapi.User, 1));
};


/** @param {!Array<!proto.usersapi.User>} value */
proto.usersapi.SearchByUsernameResponse.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usersapi.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usersapi.User}
 */
proto.usersapi.SearchByUsernameResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usersapi.User, opt_index);
};


proto.usersapi.SearchByUsernameResponse.prototype.clearUsersList = function() {
  this.setUsersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.GetFriendsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usersapi.GetFriendsResponse.repeatedFields_, null);
};
goog.inherits(proto.usersapi.GetFriendsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.GetFriendsResponse.displayName = 'proto.usersapi.GetFriendsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usersapi.GetFriendsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.GetFriendsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.GetFriendsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.GetFriendsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetFriendsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    friendsList: jspb.Message.toObjectList(msg.getFriendsList(),
    proto.usersapi.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.GetFriendsResponse}
 */
proto.usersapi.GetFriendsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.GetFriendsResponse;
  return proto.usersapi.GetFriendsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.GetFriendsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.GetFriendsResponse}
 */
proto.usersapi.GetFriendsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.addFriends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.GetFriendsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.GetFriendsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.GetFriendsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetFriendsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User friends = 1;
 * @return {!Array<!proto.usersapi.User>}
 */
proto.usersapi.GetFriendsResponse.prototype.getFriendsList = function() {
  return /** @type{!Array<!proto.usersapi.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usersapi.User, 1));
};


/** @param {!Array<!proto.usersapi.User>} value */
proto.usersapi.GetFriendsResponse.prototype.setFriendsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usersapi.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usersapi.User}
 */
proto.usersapi.GetFriendsResponse.prototype.addFriends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usersapi.User, opt_index);
};


proto.usersapi.GetFriendsResponse.prototype.clearFriendsList = function() {
  this.setFriendsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.GetPendingFriendsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usersapi.GetPendingFriendsResponse.repeatedFields_, null);
};
goog.inherits(proto.usersapi.GetPendingFriendsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.GetPendingFriendsResponse.displayName = 'proto.usersapi.GetPendingFriendsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usersapi.GetPendingFriendsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.GetPendingFriendsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.GetPendingFriendsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.GetPendingFriendsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetPendingFriendsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingFriendsList: jspb.Message.toObjectList(msg.getPendingFriendsList(),
    proto.usersapi.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.GetPendingFriendsResponse}
 */
proto.usersapi.GetPendingFriendsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.GetPendingFriendsResponse;
  return proto.usersapi.GetPendingFriendsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.GetPendingFriendsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.GetPendingFriendsResponse}
 */
proto.usersapi.GetPendingFriendsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.addPendingFriends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.GetPendingFriendsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.GetPendingFriendsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.GetPendingFriendsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetPendingFriendsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingFriendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User pending_friends = 1;
 * @return {!Array<!proto.usersapi.User>}
 */
proto.usersapi.GetPendingFriendsResponse.prototype.getPendingFriendsList = function() {
  return /** @type{!Array<!proto.usersapi.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usersapi.User, 1));
};


/** @param {!Array<!proto.usersapi.User>} value */
proto.usersapi.GetPendingFriendsResponse.prototype.setPendingFriendsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usersapi.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usersapi.User}
 */
proto.usersapi.GetPendingFriendsResponse.prototype.addPendingFriends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usersapi.User, opt_index);
};


proto.usersapi.GetPendingFriendsResponse.prototype.clearPendingFriendsList = function() {
  this.setPendingFriendsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.PendingFriendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.PendingFriendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.PendingFriendRequest.displayName = 'proto.usersapi.PendingFriendRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.PendingFriendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.PendingFriendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.PendingFriendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.PendingFriendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requester: (f = msg.getRequester()) && proto.usersapi.User.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.PendingFriendRequest}
 */
proto.usersapi.PendingFriendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.PendingFriendRequest;
  return proto.usersapi.PendingFriendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.PendingFriendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.PendingFriendRequest}
 */
proto.usersapi.PendingFriendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.User;
      reader.readMessage(value,proto.usersapi.User.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.PendingFriendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.PendingFriendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.PendingFriendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.PendingFriendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usersapi.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional User requester = 1;
 * @return {?proto.usersapi.User}
 */
proto.usersapi.PendingFriendRequest.prototype.getRequester = function() {
  return /** @type{?proto.usersapi.User} */ (
    jspb.Message.getWrapperField(this, proto.usersapi.User, 1));
};


/** @param {?proto.usersapi.User|undefined} value */
proto.usersapi.PendingFriendRequest.prototype.setRequester = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.usersapi.PendingFriendRequest.prototype.clearRequester = function() {
  this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.usersapi.PendingFriendRequest.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.usersapi.PendingFriendRequest.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.usersapi.PendingFriendRequest.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.usersapi.PendingFriendRequest.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.usersapi.PendingFriendRequest.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.GetFriendRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usersapi.GetFriendRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.usersapi.GetFriendRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.GetFriendRequestsResponse.displayName = 'proto.usersapi.GetFriendRequestsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usersapi.GetFriendRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.GetFriendRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.GetFriendRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.GetFriendRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetFriendRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    friendRequestsList: jspb.Message.toObjectList(msg.getFriendRequestsList(),
    proto.usersapi.PendingFriendRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.GetFriendRequestsResponse}
 */
proto.usersapi.GetFriendRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.GetFriendRequestsResponse;
  return proto.usersapi.GetFriendRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.GetFriendRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.GetFriendRequestsResponse}
 */
proto.usersapi.GetFriendRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usersapi.PendingFriendRequest;
      reader.readMessage(value,proto.usersapi.PendingFriendRequest.deserializeBinaryFromReader);
      msg.addFriendRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.GetFriendRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.GetFriendRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.GetFriendRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.GetFriendRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriendRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usersapi.PendingFriendRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingFriendRequest friend_requests = 1;
 * @return {!Array<!proto.usersapi.PendingFriendRequest>}
 */
proto.usersapi.GetFriendRequestsResponse.prototype.getFriendRequestsList = function() {
  return /** @type{!Array<!proto.usersapi.PendingFriendRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usersapi.PendingFriendRequest, 1));
};


/** @param {!Array<!proto.usersapi.PendingFriendRequest>} value */
proto.usersapi.GetFriendRequestsResponse.prototype.setFriendRequestsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usersapi.PendingFriendRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usersapi.PendingFriendRequest}
 */
proto.usersapi.GetFriendRequestsResponse.prototype.addFriendRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usersapi.PendingFriendRequest, opt_index);
};


proto.usersapi.GetFriendRequestsResponse.prototype.clearFriendRequestsList = function() {
  this.setFriendRequestsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.FriendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.FriendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.FriendRequest.displayName = 'proto.usersapi.FriendRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.FriendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.FriendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.FriendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.FriendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesterId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    receiverId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.FriendRequest}
 */
proto.usersapi.FriendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.FriendRequest;
  return proto.usersapi.FriendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.FriendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.FriendRequest}
 */
proto.usersapi.FriendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequesterId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.FriendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.FriendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.FriendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.FriendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesterId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 requester_id = 1;
 * @return {number}
 */
proto.usersapi.FriendRequest.prototype.getRequesterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.usersapi.FriendRequest.prototype.setRequesterId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 receiver_id = 2;
 * @return {number}
 */
proto.usersapi.FriendRequest.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.usersapi.FriendRequest.prototype.setReceiverId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.AddFriendRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.AddFriendRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.AddFriendRequestResponse.displayName = 'proto.usersapi.AddFriendRequestResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.AddFriendRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.AddFriendRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.AddFriendRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.AddFriendRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.AddFriendRequestResponse}
 */
proto.usersapi.AddFriendRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.AddFriendRequestResponse;
  return proto.usersapi.AddFriendRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.AddFriendRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.AddFriendRequestResponse}
 */
proto.usersapi.AddFriendRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.usersapi.AddFriendRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.AddFriendRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.AddFriendRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.AddFriendRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.AddFriendRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AddFriendRequestStatus status = 1;
 * @return {!proto.usersapi.AddFriendRequestStatus}
 */
proto.usersapi.AddFriendRequestResponse.prototype.getStatus = function() {
  return /** @type {!proto.usersapi.AddFriendRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.usersapi.AddFriendRequestStatus} value */
proto.usersapi.AddFriendRequestResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usersapi.EditProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usersapi.EditProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.usersapi.EditProfileRequest.displayName = 'proto.usersapi.EditProfileRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usersapi.EditProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usersapi.EditProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usersapi.EditProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.EditProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newDisplayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newAvatar: msg.getNewAvatar_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usersapi.EditProfileRequest}
 */
proto.usersapi.EditProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usersapi.EditProfileRequest;
  return proto.usersapi.EditProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usersapi.EditProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usersapi.EditProfileRequest}
 */
proto.usersapi.EditProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewDisplayName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewAvatar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usersapi.EditProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usersapi.EditProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usersapi.EditProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usersapi.EditProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewAvatar_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string new_display_name = 1;
 * @return {string}
 */
proto.usersapi.EditProfileRequest.prototype.getNewDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.usersapi.EditProfileRequest.prototype.setNewDisplayName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes new_avatar = 2;
 * @return {!(string|Uint8Array)}
 */
proto.usersapi.EditProfileRequest.prototype.getNewAvatar = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes new_avatar = 2;
 * This is a type-conversion wrapper around `getNewAvatar()`
 * @return {string}
 */
proto.usersapi.EditProfileRequest.prototype.getNewAvatar_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewAvatar()));
};


/**
 * optional bytes new_avatar = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewAvatar()`
 * @return {!Uint8Array}
 */
proto.usersapi.EditProfileRequest.prototype.getNewAvatar_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewAvatar()));
};


/** @param {!(string|Uint8Array)} value */
proto.usersapi.EditProfileRequest.prototype.setNewAvatar = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.usersapi.AddFriendRequestStatus = {
  SENT_FRIEND_REQUEST: 0,
  ADDED_AS_FRIEND: 1
};

goog.object.extend(exports, proto.usersapi);
