
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import { useStore } from "vuex";
import { PROFILE_ROUTE } from "@/constants";
import { Uploader, UploaderFileListItem, Toast } from "vant";

export default defineComponent({
  setup() {
    const store = useStore();
    const self = store.state.user.self;
    const initialDisplayName = self.displayName;
    return { store, self, initialDisplayName };
  },
  methods: {
    editProfile() {
      let updateProfile;

      if (this.fileList.length != 0) {
        updateProfile = () =>
          this.store.dispatch("editOwnProfile", {
            newAvatar: this.fileList[0].file,
            newDisplayName: this.displayName,
          });
      } else {
        updateProfile = () =>
          this.store.dispatch("editOwnProfile", {
            newDisplayName: this.displayName,
          });
      }
      updateProfile()
        .then(() => {
          Toast.success("Updated profile!");
          this.$router.replace(PROFILE_ROUTE);
        })
        .catch((err) => Toast.fail(`Failed to update profile: ${err.message}`));
    },
  },
  data() {
    return {
      PROFILE_ROUTE,
      fileList: [] as UploaderFileListItem[],
      displayName: this.initialDisplayName,
      charCountValidator: {
        validator: (val: string) => {
          return val.length >= 4 && val.length <= 16;
        },
        message: "Must be between 4 to 16 characters",
      },
    };
  },
  components: {
    Header,
    Uploader,
  },
});
