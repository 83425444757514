import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1eec4107"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchbar" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_search = _resolveComponent("van-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_search, {
      placeholder: _ctx.placeholder,
      modelValue: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSearchQuery($event.target.value))),
      onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSearchQuery(''))),
      background: "none",
      shape: "round"
    }, {
      "left-icon": _withCtx(() => [
        _createElementVNode("img", {
          id: "search-icon",
          src: _ctx.SearchIcon
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["placeholder", "modelValue"])
  ]))
}