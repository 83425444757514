
import { Dayjs } from "dayjs";
import { defineComponent, PropType } from "vue";
import { formatDistance } from "@/utils/geolocation";
import { formatDateTime } from "@/utils/date";
import { Row, Col } from "vant";
import CircleAvatar from "./CircleAvatar.vue";
import { User } from "@/interfaces";

export default defineComponent({
  components: {
    "van-row": Row,
    "van-col": Col,
    CircleAvatar,
  },
  props: {
    distance: {
      type: Number,
      required: true,
    },
    gemCreator: {
      type: Object as PropType<User>,
      required: true,
    },
    dropTime: {
      type: Dayjs,
      required: true,
    },
  },
  computed: {
    displayDistance() {
      return `${formatDistance(this.distance)} away`;
    },
    displayDropDateTime() {
      return `${formatDateTime(this.dropTime)}`;
    },
  },
});
