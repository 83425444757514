
import { defineComponent, PropType } from "vue";
import { Gem } from "@/interfaces";

export default defineComponent({
  props: {
    gem: {
      type: Object as PropType<Gem>,
      required: true,
    },
    // pseudo-CSS
    gemMessageStyle: {
      type: String,
      required: true,
    },
    // "start" | "end" sorry I made this weaker typing again haha
    justify: {
      type: String,
      required: true,
    },
    isSentBySelf: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    openMessage(): string {
      const creator = this.gem.createdBy;
      if (this.isSentBySelf) {
        return `${creator.displayName} opened your gem!`;
      } else return `You opened ${creator.displayName}'s gem!`;
    },
  },
});
