
import { defineComponent } from "vue";
import { getEnumKeyByEnumValue } from "@/utils/enum";
import { GemColor } from "@/interfaces";

export default defineComponent({
  props: {
    gemColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    getGemImageUrl(): string {
      const gemColorName = getEnumKeyByEnumValue(GemColor, this.gemColor);
      return require(`@/assets/images/${gemColorName.toLowerCase()}_2048.png`);
    },
  },
});
