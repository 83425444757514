import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10168307"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "big-header overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_row = _resolveComponent("van-row")!
  const _component_Image = _resolveComponent("Image")!
  const _component_SemiCircleBg = _resolveComponent("SemiCircleBg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_van_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_Image, {
          class: "gem-image overlay",
          src: _ctx.gemImgSrc,
          "show-loading": false
        }, null, 8, ["src"])
      ]),
      _: 1
    }),
    _createVNode(_component_SemiCircleBg, { color: _ctx.color }, null, 8, ["color"])
  ], 64))
}