import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createBlock(_component_Swiper, {
    ref: "swiperRef",
    class: "gem-color-carousel",
    "slides-per-view": 4,
    "space-between": 25,
    "centered-slides": true,
    "slide-to-clicked-slide": true,
    "initial-slide": _ctx.initialIndex,
    onRealIndexChange: _ctx.onRealIndexChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
        return (_openBlock(), _createBlock(_component_SwiperSlide, { key: color }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              round: "",
              class: "gem-color-button",
              color: color
            }, null, 8, ["color"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["initial-slide", "onRealIndexChange"]))
}