
import { defineComponent } from "vue";
import { GEM_LOGS_ROUTE } from "@/constants";
import CircleAvatar from "@/components/CircleAvatar.vue";
import Header from "@/components/Header.vue";
import GemMessage from "./GemMessage.vue";
import { mapState, useStore } from "vuex";
import { Gem, GemLogsWithFriend, User } from "@/interfaces";

export default defineComponent({
  components: {
    Header,
    CircleAvatar,
    GemMessage,
  },

  setup() {
    const store = useStore();

    return {
      store,
      GEM_LOGS_ROUTE,
    };
  },
  computed: {
    ...mapState({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      gemLog: (state: any) => {
        const logs = state.gems.selectedGemLog as GemLogsWithFriend;
        const sortedGems = [...logs.gems].sort((gem1, gem2) =>
          gem1.createdAt.diff(gem2.createdAt)
        );

        return {
          friend: logs.friend,
          gems: sortedGems,
        } as GemLogsWithFriend;
      },
      selfUser: (state: any) => state.user.self as User,
    }),
    gemsAndIsSentBySelf(): { gem: Gem; isSentBySelf: boolean }[] {
      return this.gemLog.gems.map((gem) => {
        return {
          gem: gem,
          isSentBySelf: gem.createdBy.userId === this.selfUser.userId,
        };
      });
    },
    friend(): User {
      console.assert(
        this.gemLog.friend !== undefined,
        "Friend must be associated with a gem log"
      );
      return this.gemLog.friend!;
    },
  },
});
