/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
var user_pb = require('./user_pb.js');
goog.exportSymbol('proto.gemsapi.DropRequest', null, global);
goog.exportSymbol('proto.gemsapi.DropResponse', null, global);
goog.exportSymbol('proto.gemsapi.Gem', null, global);
goog.exportSymbol('proto.gemsapi.GemColor', null, global);
goog.exportSymbol('proto.gemsapi.GemLogs', null, global);
goog.exportSymbol('proto.gemsapi.GemLogsWithFriend', null, global);
goog.exportSymbol('proto.gemsapi.GemMessage', null, global);
goog.exportSymbol('proto.gemsapi.GetPendingCollectionForUserResponse', null, global);
goog.exportSymbol('proto.gemsapi.PickUpRequest', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.Gem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.Gem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.Gem.displayName = 'proto.gemsapi.Gem';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.Gem.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.Gem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.Gem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.Gem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitude: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    longitude: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    creator: (f = msg.getCreator()) && user_pb.User.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && user_pb.User.toObject(includeInstance, f),
    receivedAt: (f = msg.getReceivedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    color: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attachment: msg.getAttachment_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.Gem}
 */
proto.gemsapi.Gem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.Gem;
  return proto.gemsapi.Gem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.Gem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.Gem}
 */
proto.gemsapi.Gem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 5:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setCreator(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedAt(value);
      break;
    case 9:
      var value = /** @type {!proto.gemsapi.GemColor} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAttachment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.Gem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.Gem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.Gem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.Gem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCreator();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReceivedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAttachment_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.gemsapi.Gem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.gemsapi.Gem.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.gemsapi.Gem.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.gemsapi.Gem.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.gemsapi.Gem.prototype.getLatitude = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.gemsapi.Gem.prototype.setLatitude = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double longitude = 4;
 * @return {number}
 */
proto.gemsapi.Gem.prototype.getLongitude = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.gemsapi.Gem.prototype.setLongitude = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional usersapi.User creator = 5;
 * @return {?proto.usersapi.User}
 */
proto.gemsapi.Gem.prototype.getCreator = function() {
  return /** @type{?proto.usersapi.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 5));
};


/** @param {?proto.usersapi.User|undefined} value */
proto.gemsapi.Gem.prototype.setCreator = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.gemsapi.Gem.prototype.clearCreator = function() {
  this.setCreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.Gem.prototype.hasCreator = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.gemsapi.Gem.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.gemsapi.Gem.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.gemsapi.Gem.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.Gem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional usersapi.User receiver = 7;
 * @return {?proto.usersapi.User}
 */
proto.gemsapi.Gem.prototype.getReceiver = function() {
  return /** @type{?proto.usersapi.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 7));
};


/** @param {?proto.usersapi.User|undefined} value */
proto.gemsapi.Gem.prototype.setReceiver = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.gemsapi.Gem.prototype.clearReceiver = function() {
  this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.Gem.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp received_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.gemsapi.Gem.prototype.getReceivedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.gemsapi.Gem.prototype.setReceivedAt = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.gemsapi.Gem.prototype.clearReceivedAt = function() {
  this.setReceivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.Gem.prototype.hasReceivedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GemColor color = 9;
 * @return {!proto.gemsapi.GemColor}
 */
proto.gemsapi.Gem.prototype.getColor = function() {
  return /** @type {!proto.gemsapi.GemColor} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.gemsapi.GemColor} value */
proto.gemsapi.Gem.prototype.setColor = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bytes attachment = 10;
 * @return {!(string|Uint8Array)}
 */
proto.gemsapi.Gem.prototype.getAttachment = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes attachment = 10;
 * This is a type-conversion wrapper around `getAttachment()`
 * @return {string}
 */
proto.gemsapi.Gem.prototype.getAttachment_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAttachment()));
};


/**
 * optional bytes attachment = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAttachment()`
 * @return {!Uint8Array}
 */
proto.gemsapi.Gem.prototype.getAttachment_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAttachment()));
};


/** @param {!(string|Uint8Array)} value */
proto.gemsapi.Gem.prototype.setAttachment = function(value) {
  jspb.Message.setProto3BytesField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.GemMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.GemMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.GemMessage.displayName = 'proto.gemsapi.GemMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.GemMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.GemMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.GemMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latitude: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    longitude: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    receiverId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    color: jspb.Message.getFieldWithDefault(msg, 5, 0),
    attachment: msg.getAttachment_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.GemMessage}
 */
proto.gemsapi.GemMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.GemMessage;
  return proto.gemsapi.GemMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.GemMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.GemMessage}
 */
proto.gemsapi.GemMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverId(value);
      break;
    case 5:
      var value = /** @type {!proto.gemsapi.GemColor} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAttachment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.GemMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.GemMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.GemMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAttachment_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.gemsapi.GemMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.gemsapi.GemMessage.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.gemsapi.GemMessage.prototype.getLatitude = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.gemsapi.GemMessage.prototype.setLatitude = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.gemsapi.GemMessage.prototype.getLongitude = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.gemsapi.GemMessage.prototype.setLongitude = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 receiver_id = 4;
 * @return {number}
 */
proto.gemsapi.GemMessage.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.gemsapi.GemMessage.prototype.setReceiverId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional GemColor color = 5;
 * @return {!proto.gemsapi.GemColor}
 */
proto.gemsapi.GemMessage.prototype.getColor = function() {
  return /** @type {!proto.gemsapi.GemColor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.gemsapi.GemColor} value */
proto.gemsapi.GemMessage.prototype.setColor = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bytes attachment = 6;
 * @return {!(string|Uint8Array)}
 */
proto.gemsapi.GemMessage.prototype.getAttachment = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes attachment = 6;
 * This is a type-conversion wrapper around `getAttachment()`
 * @return {string}
 */
proto.gemsapi.GemMessage.prototype.getAttachment_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAttachment()));
};


/**
 * optional bytes attachment = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAttachment()`
 * @return {!Uint8Array}
 */
proto.gemsapi.GemMessage.prototype.getAttachment_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAttachment()));
};


/** @param {!(string|Uint8Array)} value */
proto.gemsapi.GemMessage.prototype.setAttachment = function(value) {
  jspb.Message.setProto3BytesField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.DropRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.DropRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.DropRequest.displayName = 'proto.gemsapi.DropRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.DropRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.DropRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.DropRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.DropRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gemMessage: (f = msg.getGemMessage()) && proto.gemsapi.GemMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.DropRequest}
 */
proto.gemsapi.DropRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.DropRequest;
  return proto.gemsapi.DropRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.DropRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.DropRequest}
 */
proto.gemsapi.DropRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.gemsapi.GemMessage;
      reader.readMessage(value,proto.gemsapi.GemMessage.deserializeBinaryFromReader);
      msg.setGemMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.DropRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.DropRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.DropRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.DropRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGemMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.gemsapi.GemMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional GemMessage gem_message = 1;
 * @return {?proto.gemsapi.GemMessage}
 */
proto.gemsapi.DropRequest.prototype.getGemMessage = function() {
  return /** @type{?proto.gemsapi.GemMessage} */ (
    jspb.Message.getWrapperField(this, proto.gemsapi.GemMessage, 1));
};


/** @param {?proto.gemsapi.GemMessage|undefined} value */
proto.gemsapi.DropRequest.prototype.setGemMessage = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.gemsapi.DropRequest.prototype.clearGemMessage = function() {
  this.setGemMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.DropRequest.prototype.hasGemMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.DropResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.DropResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.DropResponse.displayName = 'proto.gemsapi.DropResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.DropResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.DropResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.DropResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.DropResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    droppedGemId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.DropResponse}
 */
proto.gemsapi.DropResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.DropResponse;
  return proto.gemsapi.DropResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.DropResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.DropResponse}
 */
proto.gemsapi.DropResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDroppedGemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.DropResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.DropResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.DropResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.DropResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDroppedGemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 dropped_gem_id = 1;
 * @return {number}
 */
proto.gemsapi.DropResponse.prototype.getDroppedGemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.gemsapi.DropResponse.prototype.setDroppedGemId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.GetPendingCollectionForUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gemsapi.GetPendingCollectionForUserResponse.repeatedFields_, null);
};
goog.inherits(proto.gemsapi.GetPendingCollectionForUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.GetPendingCollectionForUserResponse.displayName = 'proto.gemsapi.GetPendingCollectionForUserResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gemsapi.GetPendingCollectionForUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.GetPendingCollectionForUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.GetPendingCollectionForUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GetPendingCollectionForUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gemsList: jspb.Message.toObjectList(msg.getGemsList(),
    proto.gemsapi.Gem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.GetPendingCollectionForUserResponse}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.GetPendingCollectionForUserResponse;
  return proto.gemsapi.GetPendingCollectionForUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.GetPendingCollectionForUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.GetPendingCollectionForUserResponse}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.gemsapi.Gem;
      reader.readMessage(value,proto.gemsapi.Gem.deserializeBinaryFromReader);
      msg.addGems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.GetPendingCollectionForUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.GetPendingCollectionForUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GetPendingCollectionForUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.gemsapi.Gem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Gem gems = 1;
 * @return {!Array<!proto.gemsapi.Gem>}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.prototype.getGemsList = function() {
  return /** @type{!Array<!proto.gemsapi.Gem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.gemsapi.Gem, 1));
};


/** @param {!Array<!proto.gemsapi.Gem>} value */
proto.gemsapi.GetPendingCollectionForUserResponse.prototype.setGemsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.gemsapi.Gem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gemsapi.Gem}
 */
proto.gemsapi.GetPendingCollectionForUserResponse.prototype.addGems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.gemsapi.Gem, opt_index);
};


proto.gemsapi.GetPendingCollectionForUserResponse.prototype.clearGemsList = function() {
  this.setGemsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.PickUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.PickUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.PickUpRequest.displayName = 'proto.gemsapi.PickUpRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.PickUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.PickUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.PickUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.PickUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.PickUpRequest}
 */
proto.gemsapi.PickUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.PickUpRequest;
  return proto.gemsapi.PickUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.PickUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.PickUpRequest}
 */
proto.gemsapi.PickUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.PickUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.PickUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.PickUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.PickUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.gemsapi.PickUpRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.gemsapi.PickUpRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.GemLogs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gemsapi.GemLogs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.GemLogs.displayName = 'proto.gemsapi.GemLogs';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.GemLogs.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.GemLogs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.GemLogs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemLogs.toObject = function(includeInstance, msg) {
  var f, obj = {
    gemLogsMap: (f = msg.getGemLogsMap()) ? f.toObject(includeInstance, proto.gemsapi.GemLogsWithFriend.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.GemLogs}
 */
proto.gemsapi.GemLogs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.GemLogs;
  return proto.gemsapi.GemLogs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.GemLogs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.GemLogs}
 */
proto.gemsapi.GemLogs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGemLogsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readMessage, proto.gemsapi.GemLogsWithFriend.deserializeBinaryFromReader, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.GemLogs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.GemLogs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.GemLogs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemLogs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGemLogsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeMessage, proto.gemsapi.GemLogsWithFriend.serializeBinaryToWriter);
  }
};


/**
 * map<int64, GemLogsWithFriend> gem_logs = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.gemsapi.GemLogsWithFriend>}
 */
proto.gemsapi.GemLogs.prototype.getGemLogsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.gemsapi.GemLogsWithFriend>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.gemsapi.GemLogsWithFriend));
};


proto.gemsapi.GemLogs.prototype.clearGemLogsMap = function() {
  this.getGemLogsMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gemsapi.GemLogsWithFriend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gemsapi.GemLogsWithFriend.repeatedFields_, null);
};
goog.inherits(proto.gemsapi.GemLogsWithFriend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.gemsapi.GemLogsWithFriend.displayName = 'proto.gemsapi.GemLogsWithFriend';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gemsapi.GemLogsWithFriend.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gemsapi.GemLogsWithFriend.prototype.toObject = function(opt_includeInstance) {
  return proto.gemsapi.GemLogsWithFriend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gemsapi.GemLogsWithFriend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemLogsWithFriend.toObject = function(includeInstance, msg) {
  var f, obj = {
    friend: (f = msg.getFriend()) && user_pb.User.toObject(includeInstance, f),
    gemsList: jspb.Message.toObjectList(msg.getGemsList(),
    proto.gemsapi.Gem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gemsapi.GemLogsWithFriend}
 */
proto.gemsapi.GemLogsWithFriend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gemsapi.GemLogsWithFriend;
  return proto.gemsapi.GemLogsWithFriend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gemsapi.GemLogsWithFriend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gemsapi.GemLogsWithFriend}
 */
proto.gemsapi.GemLogsWithFriend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setFriend(value);
      break;
    case 2:
      var value = new proto.gemsapi.Gem;
      reader.readMessage(value,proto.gemsapi.Gem.deserializeBinaryFromReader);
      msg.addGems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gemsapi.GemLogsWithFriend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gemsapi.GemLogsWithFriend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gemsapi.GemLogsWithFriend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gemsapi.GemLogsWithFriend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriend();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getGemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.gemsapi.Gem.serializeBinaryToWriter
    );
  }
};


/**
 * optional usersapi.User friend = 1;
 * @return {?proto.usersapi.User}
 */
proto.gemsapi.GemLogsWithFriend.prototype.getFriend = function() {
  return /** @type{?proto.usersapi.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 1));
};


/** @param {?proto.usersapi.User|undefined} value */
proto.gemsapi.GemLogsWithFriend.prototype.setFriend = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.gemsapi.GemLogsWithFriend.prototype.clearFriend = function() {
  this.setFriend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.gemsapi.GemLogsWithFriend.prototype.hasFriend = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Gem gems = 2;
 * @return {!Array<!proto.gemsapi.Gem>}
 */
proto.gemsapi.GemLogsWithFriend.prototype.getGemsList = function() {
  return /** @type{!Array<!proto.gemsapi.Gem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.gemsapi.Gem, 2));
};


/** @param {!Array<!proto.gemsapi.Gem>} value */
proto.gemsapi.GemLogsWithFriend.prototype.setGemsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.gemsapi.Gem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gemsapi.Gem}
 */
proto.gemsapi.GemLogsWithFriend.prototype.addGems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.gemsapi.Gem, opt_index);
};


proto.gemsapi.GemLogsWithFriend.prototype.clearGemsList = function() {
  this.setGemsList([]);
};


/**
 * @enum {number}
 */
proto.gemsapi.GemColor = {
  PURPLE: 0,
  PINK: 1,
  BLUE: 2,
  BLACK: 3,
  YELLOW: 4,
  GREEN: 5
};

goog.object.extend(exports, proto.gemsapi);
