import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createBlock(_component_Image, {
    round: "",
    fit: "cover",
    src: _ctx.src,
    style: _normalizeStyle(_ctx.dimensions),
    "show-loading": _ctx.showLoading,
    "show-error": _ctx.showError
  }, null, 8, ["src", "style", "show-loading", "show-error"]))
}