
import { defineComponent } from "vue";
import { HOME_ROUTE, LOGIN_ROUTE } from "@/constants";
import { useStore } from "vuex";
import { Toast } from "vant";
import { GemColor } from "@/interfaces";
import WelcomeGem from "./WelcomeGem.vue";

export default defineComponent({
  data() {
    return {
      fullName: "",
      username: "",
      password: "",
      confirmPassword: "",
      store: useStore(),
      GemColor,
      charCountValidator: {
        validator: (val: string) => {
          return val.length >= 4 && val.length <= 16;
        },
        message: "Must be between 4 to 16 characters",
      },
    };
  },
  components: {
    WelcomeGem,
  },
  methods: {
    handleRegister() {
      this.store
        .dispatch("register", {
          displayName: this.fullName,
          username: this.username,
          password: this.password,
        })
        .then(() => this.$router.push(HOME_ROUTE))
        .catch((err) => Toast.fail(`Failed to register: ${err.message}`));
    },
    goToLoginPage() {
      this.$router.push(LOGIN_ROUTE);
    },
    validatePasswordConfirmation(val: string) {
      return val === this.password;
    },
  },
});
