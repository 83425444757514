/**
 * @fileoverview gRPC-Web generated client stub for gemsapi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var user_pb = require('./user_pb.js')
const proto = {};
proto.gemsapi = require('./gem_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gemsapi.GemServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gemsapi.GemServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gemsapi.DropRequest,
 *   !proto.gemsapi.DropResponse>}
 */
const methodDescriptor_GemService_Drop = new grpc.web.MethodDescriptor(
  '/gemsapi.GemService/Drop',
  grpc.web.MethodType.UNARY,
  proto.gemsapi.DropRequest,
  proto.gemsapi.DropResponse,
  /**
   * @param {!proto.gemsapi.DropRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.DropResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.gemsapi.DropRequest,
 *   !proto.gemsapi.DropResponse>}
 */
const methodInfo_GemService_Drop = new grpc.web.AbstractClientBase.MethodInfo(
  proto.gemsapi.DropResponse,
  /**
   * @param {!proto.gemsapi.DropRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.DropResponse.deserializeBinary
);


/**
 * @param {!proto.gemsapi.DropRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.gemsapi.DropResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gemsapi.DropResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gemsapi.GemServiceClient.prototype.drop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gemsapi.GemService/Drop',
      request,
      metadata || {},
      methodDescriptor_GemService_Drop,
      callback);
};


/**
 * @param {!proto.gemsapi.DropRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gemsapi.DropResponse>}
 *     Promise that resolves to the response
 */
proto.gemsapi.GemServicePromiseClient.prototype.drop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gemsapi.GemService/Drop',
      request,
      metadata || {},
      methodDescriptor_GemService_Drop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.gemsapi.GetPendingCollectionForUserResponse>}
 */
const methodDescriptor_GemService_GetPendingCollectionForUser = new grpc.web.MethodDescriptor(
  '/gemsapi.GemService/GetPendingCollectionForUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.gemsapi.GetPendingCollectionForUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.GetPendingCollectionForUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.gemsapi.GetPendingCollectionForUserResponse>}
 */
const methodInfo_GemService_GetPendingCollectionForUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.gemsapi.GetPendingCollectionForUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.GetPendingCollectionForUserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.gemsapi.GetPendingCollectionForUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gemsapi.GetPendingCollectionForUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gemsapi.GemServiceClient.prototype.getPendingCollectionForUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gemsapi.GemService/GetPendingCollectionForUser',
      request,
      metadata || {},
      methodDescriptor_GemService_GetPendingCollectionForUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gemsapi.GetPendingCollectionForUserResponse>}
 *     Promise that resolves to the response
 */
proto.gemsapi.GemServicePromiseClient.prototype.getPendingCollectionForUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gemsapi.GemService/GetPendingCollectionForUser',
      request,
      metadata || {},
      methodDescriptor_GemService_GetPendingCollectionForUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gemsapi.PickUpRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_GemService_PickUp = new grpc.web.MethodDescriptor(
  '/gemsapi.GemService/PickUp',
  grpc.web.MethodType.UNARY,
  proto.gemsapi.PickUpRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.gemsapi.PickUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.gemsapi.PickUpRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_GemService_PickUp = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.gemsapi.PickUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.gemsapi.PickUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gemsapi.GemServiceClient.prototype.pickUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gemsapi.GemService/PickUp',
      request,
      metadata || {},
      methodDescriptor_GemService_PickUp,
      callback);
};


/**
 * @param {!proto.gemsapi.PickUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.gemsapi.GemServicePromiseClient.prototype.pickUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gemsapi.GemService/PickUp',
      request,
      metadata || {},
      methodDescriptor_GemService_PickUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.gemsapi.GemLogs>}
 */
const methodDescriptor_GemService_GetGemLogs = new grpc.web.MethodDescriptor(
  '/gemsapi.GemService/GetGemLogs',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.gemsapi.GemLogs,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.GemLogs.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.gemsapi.GemLogs>}
 */
const methodInfo_GemService_GetGemLogs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.gemsapi.GemLogs,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gemsapi.GemLogs.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.gemsapi.GemLogs)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gemsapi.GemLogs>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gemsapi.GemServiceClient.prototype.getGemLogs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gemsapi.GemService/GetGemLogs',
      request,
      metadata || {},
      methodDescriptor_GemService_GetGemLogs,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gemsapi.GemLogs>}
 *     Promise that resolves to the response
 */
proto.gemsapi.GemServicePromiseClient.prototype.getGemLogs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gemsapi.GemService/GetGemLogs',
      request,
      metadata || {},
      methodDescriptor_GemService_GetGemLogs);
};


module.exports = proto.gemsapi;

