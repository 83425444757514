
import { defineComponent, onMounted } from "vue";
import BackSwipe from "@/components/BackSwipe.vue";
import FriendCell from "@/components/FriendCell.vue";
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import { PendingFriendRequest } from "@/interfaces";
import { Toast } from "vant";

export default defineComponent({
  setup() {
    const store = useStore();
    const fetchFriendRequests = () => store.dispatch("getFriendRequests");

    onMounted(() => fetchFriendRequests);
  },
  components: {
    BackSwipe,
    FriendCell,
    Header,
  },
  data() {
    return {
      store: useStore(),
    };
  },
  computed: {
    pendingFriendRequests(): PendingFriendRequest {
      return this.store.state.user.friendRequests;
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    acceptFriendRequest(requesterId: number) {
      const acceptRequest = () => this.store.dispatch("acceptFriendRequest", requesterId);
      acceptRequest()
        .then(() => {
          Toast.success("Friend request accepted!");
        })
        .catch((err) => Toast.fail(`Failed to accept request: ${err.message}`));
    },
    declineFriendRequest(requesterId: number) {
      const declineRequest = () => this.store.dispatch("declineFriendRequest", requesterId);
      declineRequest()
        .then(() => {
          Toast.success("Friend request declined!");
        })
        .catch((err) => Toast.fail(`Failed to decline request: ${err.message}`));
    },
  },
});
