
import { defineComponent, PropType } from "vue";
import { Gem, GemColor } from "@/interfaces";
import { getEnumKeyByEnumValue } from "@/utils/enum";
import { getLocationNameFromLatLng } from "@/utils/geolocation";

export default defineComponent({
  props: {
    gem: {
      type: Object as PropType<Gem>,
      required: true,
    },
    gemMessageStyle: {
      type: String,
      required: true,
    },
    justify: {
      type: String,
      required: true,
    },
    isSentBySelf: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    getLocationNameFromLatLng(this.gem.position).then(
      (address) => (this.gemLocation = address)
    );
  },
  data() {
    return {
      gemLocation: "" as string,
    };
  },
  computed: {
    getGemImageUrl(): string {
      const gemColorName = getEnumKeyByEnumValue(GemColor, this.gem.color);
      return require(`@/assets/images/${gemColorName.toLowerCase()}_2048.png`);
    },
  },
});
