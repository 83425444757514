
import { defineComponent } from "vue";
import { Search } from "vant";
import SearchIcon from "@/assets/icons/search-icon.svg";

export default defineComponent({
  setup() {
    return { SearchIcon };
  },
  components: {
    "van-search": Search,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateSearchQuery(searchQuery: string) {
      this.$emit("update:modelValue", searchQuery);
    },
  },
});
