
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Button } from "vant";
import { GemColor } from "@/interfaces";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper";

import "swiper/swiper-bundle.css";

export default defineComponent({
  components: {
    Button,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const initialIndex = store.state.gems.dropGemFormState.colorIndex;

    return {
      store,
      colors: Object.values(GemColor),
      initialIndex,
    };
  },
  methods: {
    onRealIndexChange(swiper: SwiperType) {
      const selectedColor: GemColor = this.colors[swiper.realIndex];
      this.store.commit("updateDropGemFormState", {
        colorIndex: swiper.realIndex,
      });
      this.$emit("selected-color-changed", selectedColor);
    },
  },
});
