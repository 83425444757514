import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Circle = _resolveComponent("Circle")!
  const _component_Marker = _resolveComponent("Marker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Circle, { options: _ctx.circleOptions }, null, 8, ["options"]),
    _createVNode(_component_Marker, { options: _ctx.userMarkerOptions }, null, 8, ["options"])
  ], 64))
}