import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1227a1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-page" }
const _hoisted_2 = { class: "overlay container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "sub-header" }
const _hoisted_5 = { class: "reverse-background-gradient" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ProfileAvatar = _resolveComponent("ProfileAvatar")!
  const _component_Cell = _resolveComponent("Cell")!
  const _component_CellGroup = _resolveComponent("CellGroup")!
  const _component_SemiCircleBg = _resolveComponent("SemiCircleBg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, { title: "" }),
      _createVNode(_component_ProfileAvatar, {
        avatarUrl: _ctx.self.avatar
      }, null, 8, ["avatarUrl"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.self.displayName), 1),
      _createElementVNode("div", _hoisted_4, "@" + _toDisplayString(_ctx.self.username), 1),
      _createVNode(_component_CellGroup, {
        border: false,
        class: "content container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Cell, {
            title: "Edit profile",
            "is-link": "",
            onClick: _ctx.goToEditProfile
          }, null, 8, ["onClick"]),
          _createVNode(_component_Cell, {
            title: "Friends",
            "is-link": "",
            onClick: _ctx.goToFriendList
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_SemiCircleBg)
    ])
  ]))
}